import { BASE_URL_API } from '../../config/config';
import jwtAxios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const jwtAuthAxios = jwtAxios.create({
	baseURL: BASE_URL_API,
	headers: {
		'Content-Type': 'application/json',
	},
});
export const jwtAuthAxiosFile = jwtAxios.create({
	baseURL: BASE_URL_API,
	headers: {
		'Content-Type': 'multipart/form-data',
	},
});
export const jwtAuthAxiosPDF = jwtAxios.create({
	baseURL: BASE_URL_API,
	headers: {
		'Content-Type': 'application/pdf',
	},
});

jwtAuthAxios.interceptors.response.use(
	(res) => res,
	(err) => {
		let isSwalShown = false;

		// Check for token invalid condition
		if (err.response && err.response.data.type === 'token-invalid') {
			isSwalShown = true;
			localStorage.clear();
			setAuthToken(null);
			window.history.replaceState({}, 'Login', '/auth-pages/login');
			window.location.reload();
		}

		// Check for unauthorized condition
		if (err.response && err.response.data.message === 'Unauthorized!') {
			isSwalShown = true;
			Swal.fire({
				icon: 'warning',
				title: 'Your session has expired for security reasons. Please log in again to continue',
				text: 'Please press OK to proceed.',
				showConfirmButton: true,
				confirmButtonText: 'OK',
				timer: null, // Disables auto close timer
				showCloseButton: false, // Disables the close button (X) in the top-right corner
				allowOutsideClick: false, // Prevents closing by clicking outside
				allowEscapeKey: false, // Prevents closing with the Escape key
				allowEnterKey: false, // Prevents closing with the Enter key
				backdrop: true, // Optionally, set backdrop to true to dim the background
			}).then((result) => {
				if (result.isConfirmed) {
					// Clear localStorage and reset token
					localStorage.clear();
					setAuthToken(null);
					// Redirect to login page
					window.history.replaceState({}, 'Login', '/auth-pages/login');
					window.location.reload();
				}
			});
		}

		// Only show toast if Swal alert is not shown
		if (!isSwalShown) {
			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 3000,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', Swal.stopTimer);
					toast.addEventListener('mouseleave', Swal.resumeTimer);
				},
			});
			Toast.fire({
				icon: 'error',
				title: err.response.data.message ?? 'There is something wrong',
			});
		}

		return Promise.reject(err);
	},
);

export const setAuthToken = (token) => {
	if (token) {
		jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		jwtAuthAxiosFile.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		jwtAuthAxiosPDF.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		localStorage.setItem('token', token);
	} else {
		delete jwtAuthAxios.defaults.headers.common['Authorization'];
		delete jwtAuthAxiosFile.defaults.headers.common['Authorization'];
		delete jwtAuthAxiosPDF.defaults.headers.common['Authorization'];
		localStorage.removeItem('token');
	}
};

export const getAuthToken = () => {
	return sessionStorage.getItem('token');
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;
