import React, { useEffect, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { adminMenu, operatorMenu, superAdminmenu, userMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import authService from '../../../services/Auth/auth.service';
import useUser from '../../../hooks/useUser';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { t } = useTranslation(['translation', 'menu']);
	const [userData, setUserdata] = useState<any | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	const { user } = useUser();
	useEffect(() => {
		setUserdata(user);
	}, []);

	if (!userData) {
		return <div>Error loading user data.</div>;
	}

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{userData?.jenis === 'admin' && (
					<>
						<Navigation menu={adminMenu} id='aside-dashboard' />
						<NavigationLine />
					</>
				)}
				{userData?.jenis === 'super admin' && (
					<>
						<Navigation menu={superAdminmenu} id='aside-dashboard' />
						<NavigationLine />
					</>
				)}
				{userData?.jenis === 'operator' && (
					<>
						<Navigation menu={operatorMenu} id='aside-dashboard' />
						<NavigationLine />
					</>
				)}
				{userData?.jenis === 'user' && (
					<>
						<Navigation menu={userMenu} id='aside-dashboard' />
						<NavigationLine />
					</>
				)}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
