import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { lazy } from 'react';
import useUser from '../hooks/useUser';
import { adminMenu, operatorMenu, userMenu, demoPagesMenu, superAdminmenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import Page404 from '../pages/presentation/auth/Page404';
import Spinner from '../components/bootstrap/Spinner';
import { any } from 'prop-types';
import Swal from 'sweetalert2';

const PAGE_404 = lazy(() => import('../pages/presentation/auth/Page404'));
const LOGIN = lazy(() => import('../pages/presentation/auth/Login'));

const CHANGE_PASSWORD = lazy(() => import('../pages/auth/ChangePassword'));

const ADMIN = {
	HOME: lazy(() => import('../pages/admin/Home')),
	USER_MANAGEMENT: lazy(() => import('../pages/admin/UserManagement')),
	ADMINISTRATION_CODE: lazy(() => import('../pages/admin/AdministrationCode')),
	INTITUTION_CONFERENCE: lazy(() => import('../pages/admin/InstitutionConference')),
	DEPARTMENT: lazy(() => import('../pages/admin/Department')),
	PHYSICAL_BUILDING: lazy(() => import('../pages/admin/PhysicalBuilding')),
	LOCATION: lazy(() => import('../pages/admin/Location')),
};

const OPERATOR = {
	HOME: lazy(() => import('../pages/operator/Home')),
	DOCUMENTS: lazy(() => import('../pages/operator/Documents')),
	CORRESPONDENCE: lazy(() => import('../pages/operator/Correspondence')),
	PROPERTY: lazy(() => import('../pages/operator/Property')),
	SERVICE_RECORD: lazy(() => import('../pages/operator/ServiceRecord')),
	RETIREMENT: lazy(() => import('../pages/operator/Retirement')),
};

const USER = {
	HOME: lazy(() => import('../pages/user/Home')),
	DOCUMENTS: lazy(() => import('../pages/user/Documents')),
	CORRESPONDENCE: lazy(() => import('../pages/user/Correspondence')),
	PROPERTY: lazy(() => import('../pages/user/Property')),
	SERVICE_RECORD: lazy(() => import('../pages/user/ServiceRecord')),
	RETIREMENT: lazy(() => import('../pages/user/Retirement')),
};

// Component to define routes
const ContentRoutesItem = () => {
	const navigate = useNavigate();
	useEffect(() => {
		try {
			const storedUser = localStorage.getItem('user');
			if (storedUser) {
				const user = JSON.parse(storedUser);
			}
		} catch (error) {
			Swal.fire({
				icon: 'success',
				title: 'Your session has expired',
				timer: 3000,
			});
			localStorage.clear();
			navigate('/auth-pages/sign-in');
		}
	}, []);

	const { user } = useUser();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (user !== undefined) {
			setLoading(false);
		}
	}, [user]);

	if (loading) {
		return <Spinner />;
	}

	const authRoutes = user
		? [
				{
					path: demoPagesMenu.changePassword.path,
					element: <CHANGE_PASSWORD />,
				},
			]
		: [];

	const superAdminRoutes =
		user?.jenis === 'super admin'
			? [
					{
						path: superAdminmenu.home.path,
						element: <ADMIN.HOME />,
					},
					{
						path: superAdminmenu.userManagement.path,
						element: <ADMIN.USER_MANAGEMENT />,
					},
					{
						path: superAdminmenu.administrationCode.path,
						element: <ADMIN.ADMINISTRATION_CODE />,
					},
					{
						path: superAdminmenu.institutionConference.path,
						element: <ADMIN.INTITUTION_CONFERENCE />,
					},
					{
						path: superAdminmenu.department.path,
						element: <ADMIN.DEPARTMENT />,
					},
					{
						path: superAdminmenu.physicalBuilding.path,
						element: <ADMIN.PHYSICAL_BUILDING />,
					},
					{
						path: superAdminmenu.location.path,
						element: <ADMIN.LOCATION />,
					},
				]
			: [];

	const adminRoutes =
		user?.jenis === 'admin'
			? [
					{
						path: adminMenu.home.path,
						element: <ADMIN.HOME />,
					},
					{
						path: adminMenu.userManagement.path,
						element: <ADMIN.USER_MANAGEMENT />,
					},
					{
						path: adminMenu.administrationCode.path,
						element: <ADMIN.ADMINISTRATION_CODE />,
					},
					// {
					// 	path: adminMenu.conferenceMission.path,
					// 	element: <ADMIN.CONFERENCE_MISSION />,
					// },
					{
						path: adminMenu.department.path,
						element: <ADMIN.DEPARTMENT />,
					},
					// {
					// 	path: adminMenu.physicalBuilding.path,
					// 	element: <ADMIN.PHYSICAL_BUILDING />,
					// },
					{
						path: superAdminmenu.location.path,
						element: <ADMIN.LOCATION />,
					},
				]
			: [];

	const operatorRoutes =
		user?.jenis === 'operator'
			? [
					{
						path: operatorMenu.home.path,
						element: <OPERATOR.HOME />,
					},
					{
						path: operatorMenu.documents.path,
						element: <OPERATOR.DOCUMENTS />,
					},
					{
						path: operatorMenu.correspondence.path,
						element: <OPERATOR.CORRESPONDENCE />,
					},
					{
						path: operatorMenu.property.path,
						element: <OPERATOR.PROPERTY />,
					},
					{
						path: operatorMenu.serviceRecord.path,
						element: <OPERATOR.SERVICE_RECORD />,
					},
					{
						path: operatorMenu.retirement.path,
						element: <OPERATOR.RETIREMENT />,
					},
				]
			: [];

	const userRoutes =
		user?.jenis === 'user'
			? [
					{
						path: userMenu.home.path,
						element: <USER.HOME />,
					},
					{
						path: userMenu.documents.path,
						element: <USER.DOCUMENTS />,
					},
					{
						path: userMenu.correspondence.path,
						element: <USER.CORRESPONDENCE />,
					},
					{
						path: userMenu.property.path,
						element: <USER.PROPERTY />,
					},
					{
						path: userMenu.serviceRecord.path,
						element: <USER.SERVICE_RECORD />,
					},
					{
						path: userMenu.retirement.path,
						element: <USER.RETIREMENT />,
					},
				]
			: [];

	const contents = [
		...authRoutes,
		...superAdminRoutes,
		...adminRoutes,
		...operatorRoutes,
		...userRoutes,
	];

	return (
		<Routes>
			{contents.map((route) => (
				<Route key={route.path} path={route.path} element={route.element} />
			))}
			{/* Redirect to login if user is not authenticated */}
			{!user && <Route path='*' element={<Navigate to='/auth-pages/login' replace />} />}
			{/* Fallback for undefined routes */}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutesItem;
