import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
			version='1.1'
			viewBox='0 0 2048 1161'
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				transform='translate(918,828)'
				d='m0 0 21 1 20 3 16 5 10 6 9 8 6 10 4 12 2 18v7l-67 1-1 47h68v133l-2-1-6-14-9-14-9-10-8-7-13-8-14-6-17-5-19-3-14-1h-28l-36 3-41 6-345 61-4-1 12-16 12-17 117-162 4-3 252-45 36-5 21-2z'
				fill='#135651'
			/>
			<path
				transform='translate(1222,838)'
				d='m0 0h7l244 43 5 5 14 19 10 14 91 126 14 19 10 14v2l-21-3-301-52-45-8-38-5-28-2h-24l-21 2-19 4-16 6-13 7-9 7-8 7-9 12-7 12-5 12h-1v-13l3-19 6-20 11-23 16-24 11-14 12-14 10-11 7-8 11-11 7-8z'
				fill='#135651'
			/>
			<path
				transform='translate(1119)'
				d='m0 0h2v85l-3 15-7 19-11 21-10 15-12 16-9 11-13 15-15 16-204 204-7 8-14 15-9 11-11 13-13 18-11 18-8 16-6 15-5 17-4 23-2 14-2-2-4-15-3-24v-29l3-22 6-24 8-23 8-16 10-18 12-17 11-14 12-14 11-12 7-8 19-19 7-8 179-179 7-8 8-8 7-8 13-14 9-11 10-12 13-18 8-14 6-15 4-14z'
				fill='#D29E18'
			/>
			<path
				transform='translate(1119,125)'
				d='m0 0h2v85l-3 15-7 19-11 21-10 15-12 16-9 11-9 10-7 8-12 13-191 191-7 8-10 11-11 14-10 13-10 15-10 18-7 15-6 18-3 15-1 10v12l2 14 5 17 2 7-5-2-8-9-8-16-5-15-4-21-1-8v-31l4-23 6-19 9-21 9-17 11-17 10-14 11-14 11-13 18-20 16-17 171-171 7-8 10-10 7-8 13-14 9-11 11-13 13-19 8-14 6-15 5-23z'
				fill='#D29E18'
			/>
			<path
				transform='translate(1120,250)'
				d='m0 0h1v86l-3 15-8 21-9 17-14 21-10 13-11 13-7 8-9 10-12 13-32 32-8 7-113 113-7 8-12 14-11 16-10 17-9 21-4 13-3 17v21l4 16 5 10 9 10 9 6 3 3h-8l-13-4-10-6-10-9-8-10-8-15-5-16-3-17v-27l3-21 5-18 9-21 10-17 12-16 9-11 11-12 9-10 164-164 7-8 11-11 7-8 14-15 9-11 11-13 12-17 8-14 6-13 5-17 3-19z'
				fill='#D29E18'
			/>
			<path
				transform='translate(1274,419)'
				d='m0 0 4 1 6 5 8 13 6 15 5 20 3 26v14l-3 24-7 25-10 23-11 20-12 18-12 16-9 11-11 13-12 13-5 6h-2l-2 4-70 70-7 8-10 10-7 8-9 10-11 14-10 13-12 19-10 21-5 15-5 25h-2v-82l3-18 5-15 11-23 12-19 12-16 8-10 11-13 11-12 9-10 13-13 7-8 65-65 7-8 14-17 12-17 12-20 9-19 6-18 3-13 1-8v-22l-3-16z'
				fill='#D29E18'
			/>
			<path
				transform='translate(1199,342)'
				d='m0 0 11 1 11 4 10 6 10 9 8 10 8 16 5 17 2 11v35l-3 18-5 18-8 18-8 15-12 17-11 13-7 8-12 13-62 62-7 8-8 8-7 8-12 14-13 17-10 15-8 14-7 15-5 15-5 23h-2v-85l3-16 7-20 12-23 13-19 10-13 11-13 11-12 4-5 3-3h2v-2h2l1-3h2v-2l8-7 32-32 7-8 12-14 12-17 10-18 8-18 5-17 2-11v-27l-3-12-7-14-10-10-10-6z'
				fill='#D29E18'
			/>
			<path
				transform='translate(1311,550)'
				d='m0 0 3 3 4 17 2 17v37l-4 25-7 24-7 19-10 20-12 19-13 17-9 11-12 14-30 32-89 89h-2l-2 4h-57l8-16 9-15 13-18 11-13 9-11 14-15 8-8 7-8 13-13 8-7 20-20 7-8 33-33 7-8 11-12 9-11 13-16 12-17 9-15 8-16 7-19 6-25z'
				fill='#D29E18'
			/>
			<path
				transform='translate(1053,945)'
				d='m0 0h30l-2 5-8 13-10 21-6 20-3 15-2 1v-74z'
				fill='#D29E18'
			/>
			<path
				transform='translate(1597,931)'
				d='m0 0h16l8 4 6 5 5 9 1 3v15l-5 10-8 7-7 3-10 1-10-2-8-6-5-6-3-6-1-5v-8l3-9 4-6 7-6zm3 3-10 4-7 8-3 7v12l5 10 7 6 7 3h12l10-5 6-7 3-9v-8l-4-10-5-5-8-5-4-1z'
				fill='#D29E18'
			/>
			<path
				transform='translate(1591,942)'
				d='m0 0h15l7 3 1 2v8l-4 5 8 11 4 3v2h-6l-5-4-6-9v-2h-5v12l5 1v2h-13l2-4v-26l-4-3zm9 3v13h6l4-4-1-5-4-4z'
				fill='#D29E18'
			/>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
