import UserImage from '../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../assets/img/wanna/wanna2.png';
import UserImage2Webp from '../../assets/img/wanna/wanna2.webp';
import UserImage3 from '../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../assets/img/wanna/wanna3.webp';
import UserImage4 from '../../assets/img/wanna/wanna4.png';
import UserImage4Webp from '../../assets/img/wanna/wanna4.webp';
import UserImage5 from '../../assets/img/wanna/wanna5.png';
import UserImage5Webp from '../../assets/img/wanna/wanna5.webp';
import UserImage6 from '../../assets/img/wanna/wanna6.png';
import UserImage6Webp from '../../assets/img/wanna/wanna6.webp';
import UserImage7 from '../../assets/img/wanna/wanna7.png';
import UserImage7Webp from '../../assets/img/wanna/wanna7.webp';
import SERVICES, { IServiceProps } from './serviceDummyData';

import User7Landing from '../../assets/img/wanna/landing1.png';
import { TColor } from '../../type/color-type';

export interface IUserProps {
	id: string;
	username: string;
	name: string;
	surname: string;
	fullName: string;
	position: string;
	email?: string;
	src: string;
	srcSet: string;
	isOnline: boolean;
	isReply?: boolean;
	color: TColor;
	fullImage?: string;
	services?: IServiceProps[];
	password: string;
}

const mark: IUserProps = {
	id: '1',
	username: 'mark',
	name: 'Mark',
	surname: 'Mononutu',
	fullName: 'Mark Mononutu',
	position: 'Admin',
	email: 'dummy1@mail.com',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
	password: '12345',
};
const stenly: IUserProps = {
	id: '2',
	username: 'stenly',
	name: 'Stenly',
	surname: 'Adam',
	fullName: 'Stenly Adam',
	position: 'Operator',
	email: 'dummy2@mail.com',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
	password: '12345',
};
const henry: IUserProps = {
	id: '3',
	username: 'hendry',
	name: 'Hendry',
	surname: 'Adam',
	fullName: 'Hendry Adam',
	position: 'User',
	email: 'dummy2@mail.com',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
	password: '12345',
};
const john: IUserProps = {
	id: '4',
	username: 'john',
	name: 'John',
	surname: 'Doe',
	fullName: 'John Doe',
	position: 'Admin',
	email: 'dummy4@mail.com',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
	password: '12345',
};
const bruno: IUserProps = {
	id: '5',
	username: 'bruno',
	name: 'Bruno',
	surname: 'Fernandes',
	fullName: 'Bruno Fernandes',
	position: 'Operator',
	email: 'dummy5@mail.com',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
	password: '12345',
};
const marcus: IUserProps = {
	id: '6',
	username: 'marcus',
	name: 'Marcus',
	surname: 'Rashford',
	fullName: 'Marcus Rashford',
	position: 'User',
	email: 'dummy6@mail.com',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
	password: '12345',
};

const andre: IUserProps = {
	id: '7',
	username: 'andre',
	name: 'Andre',
	surname: 'Onana',
	fullName: 'Andre Onana',
	position: 'User',
	email: 'dummy7@mail.com',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
	password: '12345',
};

const USERS: { [key: string]: IUserProps } = {
	MARK: mark,
	STENLY: stenly,
	HENRY: henry,
	JOHN: john,
	Bruno: bruno,
	MARCUS: marcus,
	ANDRE: andre,
};

export function getUserDataWithUsername(username: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].username.toString() === username)];
}

export function getUserDataWithId(id?: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].id.toString() === id.toString())];
}

export function login(username: string, password: string): boolean {
	// console.log('Attempting login with username:', username, 'and password:', password); // Debugging line
	const user = getUserDataWithUsername(username);
	// console.log('User found:', user); // Debugging line
	if (user && user.password === password) {
		// console.log('Login successful'); // Debugging line
		return true;
	}
	// console.log('Login failed'); // Debugging line
	return false;
}

export default USERS;
