import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../../pages/presentation/auth/Login';
import ContentRoutesItem from '../../routes/ContentRoutesItem';

const LOGIN = lazy(() => import('../../pages/presentation/auth/Login'));

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	return (
		<Routes>
			<Route path='auth-pages/login' element={<LOGIN />} />
			<Route path='/*' element={<ContentRoutesItem />} />
		</Routes>
	);
};

export default ContentRoutes;
