import React, { useContext } from 'react';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import CommonHeaderRight from './CommonHeaderRight';
import useUser from '../../../hooks/useUser';

const DefaultHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useUser();

	// Function to get greeting based on current time
	const getGreeting = () => {
		const currentTime = new Date();
		const currentHour = currentTime.getHours();

		if (currentHour >= 5 && currentHour < 12) {
			return 'Good Morning';
		} else if (currentHour >= 12 && currentHour < 18) {
			return 'Good Afternoon';
		} else if (currentHour >= 18 && currentHour < 22) {
			return 'Good Evening';
		} else {
			return 'Good Night';
		}
	};

	// Function to format the current date
	const getCurrentDate = () => {
		const options: Intl.DateTimeFormatOptions = {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		};
		return new Date().toLocaleDateString('en-US', options);
	};

	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames('fs-3', 'fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								{`${getGreeting()}, ${user?.nama_lengkap}`}
								<h5>{getCurrentDate()}</h5>
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<HeaderRight>
				<CommonHeaderRight />
			</HeaderRight>
		</Header>
	);
};

export default DefaultHeader;
